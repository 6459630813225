import Request from './request'
import config from '../config/index'
const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
export const mapUrl = process.env.NODE_ENV === 'development' ? config.mapUrl.dev : config.mapUrl.pro
export const socketUrl = process.env.NODE_ENV === 'development' ? config.socketUrl.dev : config.socketUrl.pro
const request = new Request({
  baseURL:baseUrl,
  timeout:1000*60*5
})
const httpRequest = (config)=>{
  //默认请求方式为GET,且一直用data作为参数
  const {method = 'GET'} = config
  if(method === 'get' || method === 'GET'){
    config.params = config.data
  }
  return request.request(config)
}
// 取消请求
export const cancelRequest = (url) => {
  return request.cancelRequest(url)
}
// 取消全部请求
export const cancelAllRequest = () => {
  return request.cancelAllRequest()
}

export default httpRequest
