import axios from 'axios'
//封装成一个类创建多个实例
class Request {
  constructor(config) {
    //创建一个axios实例
    this.instance = axios.create(config)
    //存放取消请求的集合
    this.cancelRequestSourceList = []
    //存放所有请求URL的集合
    this.requestUrlList = []
    //类拦截器
    //请求时拦截
    this.instance.interceptors.request.use((config => {
      config.headers['RuntoAccessType'] = 'Endpoint'
      config.headers['Authorization'] = localStorage.getItem('token')
      return config
    }), (err => {
        return Promise.reject(err)
    }))
    //响应时拦截
    this.instance.interceptors.response.use((res => {
      console.log(res);
      const {data,status} = res
      if(status===200){
        return data
      }else{
        return false
      }
    }), (err => {
      return Promise.reject(err)
    }))
  }
  //获取指定url在cancelRequestSourceList中的索引
  getSourceIndex(url){
    return this.cancelRequestSourceList.findIndex((item)=>{
      return Object.keys(item)[0] === url
    })
  }
  //删除requestUrlList和cancelRequestSourceList
  delUrl(url){
    const urlIndex = this.requestUrlList.findIndex(u=>u===url)
    const sourceIndex = this.getSourceIndex(url)
    //删除url和cancel方法
    urlIndex!==-1&&this.requestUrlList.splice(urlIndex,1)
    sourceIndex!==-1&&this.cancelRequestSourceList.splice(sourceIndex)
  }
  //取消全部请求
  cancelAllRequest(){
    this.cancelRequestSourceList.forEach(source=>{
      const key = Object.keys(source)[0]
      source[key]()
    })
  }
  //取消请求
  cancelRequest(url){
    if(typeof url === 'string'){
      //取消当个请求
      const sourceIndex = this.getSourceIndex(url)
      sourceIndex>=0 && this.cancelRequestSourceList[sourceIndex][url]()
    }else{
      //存在多个需要取消请求的地址
      url.forEach(u=>{
        const sourceIndex = this.getSourceIndex(u)
        sourceIndex>=0 && this.cancelRequestSourceList[sourceIndex][u]()
      })
    }
  }
  //发起请求
  request(config) {
    return new Promise((resolve, reject) => {
      const url = config.url
      //url存在取消请求方法和当前请求url
      if (url) {
        this.requestUrlList.push(url)
        config.cancelToken = new axios.CancelToken(c=>{
           this.cancelRequestSourceList.push({ [url]: c})
        })
      }
      this.instance.request(config).then(res=>{
        resolve(res)
      })
      .catch(err=>{
        reject(err)
        console.log(err);
      })
      .finally(()=>{
        url && this.delUrl(url)
      })
    })
  }
}

export default Request