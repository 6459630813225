<!-- 十分钟健身圈 -->
<template>
  <div>
    <div id="overMap" style="width: 100%;height:100%"></div>
    <div id="popups">
      <div class="popupBox1">

      <!-- <img v-if="!datas.popupData.typeId"
          @click="srcClick(datas.popupData.images ? datas.popupData.images : datas.popupData.icon)"
                                              :src="datas.popupData.images ? baseUrl + datas.popupData.images : baseUrl + datas.popupData.icon"> -->
        <div>
          <div style="margin-bottom: 4px;font-size: 20px;">
            {{
              datas.popupData.industryName || datas.popupData.fieldName || datas.popupData.orgName ||
              datas.popupData.venueName
            }}
          </div>
          <div class="contentPopup" style="display: flex;flex-wrap: wrap;justify-content: space-between;">
            <div style="font-size: 12px;min-width: 45%;" v-if="!datas.popupData.typeId">
              位置：{{ datas.popupData.location || datas.popupData.fieldName }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.typeId">
              位置：{{ datas.popupData.municipality + datas.popupData.district + datas.popupData.street }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.tag">标签：{{ datas.popupData.tag }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.industryPhone">联系电话：{{
              datas.popupData.industryPhone }}
            </div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.neighborhood">居委会：{{
              datas.popupData.neighborhood }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.maintenance">维护单位：{{
              datas.popupData.maintenance }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.type">产业类型：{{ datas.popupData.type }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.openness">收费情况：{{ datas.popupData.openness
            }}</div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.businessTime">
              营业时间：{{ datas.popupData.businessTime || datas.popupData.fieldName }}
            </div>
            <div style="font-size: 12px;min-width: 45%;" v-if="datas.popupData.businessTime">
              营业状态：<span>{{
                datas.popupData.isBusiness
                === '0' ? '关门' : '营业'
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 12px;" v-if="datas.popupData.des">介绍：{{ datas.popupData.des }} </div>
      <div style="font-size: 12px;" v-if="datas.popupData.typeId">
        介绍：{{
          datas.popupData.typeId === 209 ? '农民体育健身工程' : datas.popupData.typeId === 210 ? '全民健身路径工程' :
          '健身场馆附属设施'
        }}</div>

      <div style="margin-bottom: 5px;">{{ datas.popupData.remark }}</div>
      <span v-if="datas.popupData.images">
        <img v-for="item in datas.popupData.images" :key="item" @click="srcClick(item)"
          :src="item ? baseUrl + item : baseUrl + item">
      </span>
      <span v-else-if="datas.popupData.icon">
        <img v-for="item in datas.popupData.icon" :key="item" @click="srcClick(item)"
          :src="item ? baseUrl + item : baseUrl + item">
      </span>
      <!-- 导航按钮 -->
      <div @click="goLocation()"
        style="border-top: 1px dashed #dcdcdc;display: flex;align-items: center;justify-content: center;padding-top: 6px;font-size: 16px;color: #333;">
        <img style="width: 21px;height: 27px;" src="../assets/images/quzhe@2x.png" alt="">到这去
      </div>
    </div>

  </div>
  <!-- 图片大图弹框 -->
  <div class="modalImg" v-if="datas.showModalImg" @click="datas.showModalImg = false">
    <img :src="datas.src">
  </div>
</template>

<script setup>
import { toRaw, ref, reactive } from '@vue/reactivity'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { wgs84_to_gcj02 } from "../assets/wgsToGCJ";
import {
  getSportmapVenue,//获取设施详情
  getSportmapSportfield,//获取体育场地详情
  getSportmapSportindustry,//获取体育产业详情
  getSportmapSportorg//获取体育组织详情
} from '../api/home'
import config from '../config/index';
let map = null
let select = null
let popup // 地图上的弹框图层
let layerType = ref('vec')
let baseUrl = config.baseUrl.pro
let popupInfo = ref(null)
let iconImg = require('../assets/images/ding.png')
const userIconImg = require('../assets/images/ding.png')
// let dataInfo = reactive({})
const prop = defineProps({
  dataInfo: Object
})
document.title = '十分钟健身圈'
// var feature // 全局map地图打点实例
var pointLayer = new ol.layer.Vector({
  source: new ol.source.Vector({
    features: []
  }),
  zIndex: 999
})
var pointLayerUser = new ol.layer.Vector({
  source: new ol.source.Vector({
    features: []
  })
})
// 邹平市图层
let mianLayerSHI = new ol.layer.Tile({
  source: new ol.source.TileSuperMapRest({
    url: 'https://www.zptiyuju.com/iserver/services/map-zpsports/rest/maps/zoupingxianji@zpgisdb',
    wrapX: true
  }),
  visible: true,
  zIndex: 1,
  projection: 'EPSG:4326'
})
// 镇图层
let mianLayerZHEN = new ol.layer.Tile({
  source: new ol.source.TileSuperMapRest({
    url: 'https://www.zptiyuju.com/iserver/services/map-zpsports/rest/maps/zoupingzhenji@zpgisdb',
    wrapX: true
  }),
  visible: true,
  zIndex: 9,
  projection: 'EPSG:4326'
})
let datas = reactive({
  pointList: [],
  popupData: {},
  src: '',
  showModalImg: false,
  // datasetNames: [['zpgisdb:sys_sportfield_sys_sportfield'], ['zpgisdb:sys_sportindustry_sys_sportindustry'], ['zpgisdb:sys_sportorg_sys_sportorg'], ['zpgisdb:sys_venue_sys_venue']],
  datasetNames: [['zpgisdb:sys_sportfield_sys_sportfield'], ['zpgisdb:sys_sportindustry_sys_sportindustry'], ['zpgisdb:sys_sportorg_sys_sportorg']],
  icon1: require('../assets/images/nongmin.png'),
  icon2: require('../assets/images/jianshensheshi.png'),
  icon3: require('../assets/images/fushusheshi.png'),
  icon4: require('../assets/images/changditubiao.png'),
  icon5: require('../assets/images/zuzhitubiao.png'),
  icon6: require('../assets/images/ticai.png')
})
let self = reactive({
  lng: '',
  lat: '',
  partnerAddress: '金域中心'
})
// 初始化地图
const initMap = () => {
  // 初始化地图
  map = new ol.Map({
    target: 'overMap',
    controls: ol.control.defaults({ attribution: false, zoom: true }),
    view: new ol.View({
      center: [117.73714744925228, 36.861579290645864],
      zoom: 14,
      projection: 'EPSG:4326',
      multiWorld: true
    }),
    layers: [
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          key: 'a23c76b3ad3bd71b88f0669ab26087c7'
        })
      }),
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          isLabel: true,
          key: 'a23c76b3ad3bd71b88f0669ab26087c7'
        })
      }),
      pointLayer,
      mianLayerSHI,
      mianLayerZHEN
    ]
  })
  // fixedPoint()
  // 初始化添加点击点位信息后展示的弹出框
  popup = new ol.Overlay({
    element: document.getElementById('popups'),
    offset: [15, -110]
  })
  map.addOverlay(popup)
  popup.setPosition(undefined)
  var feature = new ol.Feature({
    geometry: new ol.geom.Point([Number(prop.dataInfo.userLocation.y), Number(prop.dataInfo.userLocation.x)])
  })
  let style = new ol.style.Style({
    image: new ol.style.Icon({
      scale: 0.6,
      src: userIconImg,
      anchor: [0.48, 0.52]
    })
  })
  feature.setStyle(style);
  pointLayerUser.getSource().addFeature(feature);
  map.addLayer(pointLayerUser)
  dingwei()
  map.on('click', function (params) {
    console.log(params);
    popup.setPosition(undefined)
  })
  let select = new ol.interaction.Select({
    layers: [pointLayer],
  });
  map.addInteraction(select)
  select.on('select', async (e) => {
    let features = e.target.getFeatures().getArray()
    if (features.length > 0) {
      if (e.selected[0].values_.attributes.type === '全民健身路径工程' || e.selected[0].values_.attributes.type === '农民体育健身工程' || e.selected[0].values_.attributes.type === '健身场馆附属设施') {
        const res = await getSportmapVenue(e.selected[0].values_.attributes.item.VENUE_ID)
        console.log(res.data);
        if (res.data.images) res.data.images = res.data.images.split(',')
        datas.popupData = res.data
        self.lat = res.data.lat
        self.lng = res.data.lng
        self.partnerAddress = res.data.municipality + res.data.district + res.data.street + res.data.venueName
      } else if (e.selected[0].values_.attributes.type === '体育场地') {
        const res = await getSportmapSportfield(e.selected[0].values_.attributes.item.FIELD_ID)
        console.log(res.data);
        if (res.data.images) res.data.images = res.data.images.split(',')
        datas.popupData = res.data
        console.log(datas.popupData);
        self.partnerAddress = res.data.location
        self.lat = res.data.lat
        self.lng = res.data.lng
      } else if (e.selected[0].values_.attributes.type === '体育产业') {
        const res = await getSportmapSportindustry(e.selected[0].values_.attributes.item.INDUSTRY_ID)
        console.log(res.data);
        if (res.data.icon) res.data.icon = res.data.icon.split(',')
        if (res.data.images) res.data.images = res.data.images.split(',')
        datas.popupData = res.data
        self.partnerAddress = res.data.location
        self.lat = res.data.lat
        self.lng = res.data.lng
      } else if (e.selected[0].values_.attributes.type === '体育组织') {
        const res = await getSportmapSportorg(e.selected[0].values_.attributes.item.ORG_ID)
        console.log(res.data);
        if (res.data.icon) res.data.icon = res.data.icon.split(',')
        if (res.data.images) res.data.images = res.data.images.split(',')
        datas.popupData = res.data
        self.partnerAddress = res.data.location
        self.lat = res.data.lat
        self.lng = res.data.lng
      }
      let feature = features[0].values_.geometry.flatCoordinates
      console.log(feature);
      popup.setPosition(feature || [36.863408, 117.743079])
      console.log(datas.popupData);
    }
  })
  // addPoint()
}
// 跳转到地图导航
const goLocation = () => {
  let a = wgs84_to_gcj02(self.lng, self.lat)
  self.lng = a[0]
  self.lat = a[1]
  wx.miniProgram.navigateTo({
    url: `/package-index/pages/location/location?latitude=${self.lat}&longitude=${self.lng}&name=${self.partnerAddress}`
  });
}
// // 地图打点
const fixedPoint = () => {
  select && map.removeInteraction(select);
  let style
  // console.log(datas.pointList);
  datas.pointList.forEach((item, index) => {
    // console.log(item);
    var feature = new ol.Feature({
      attributes: { item: item.properties, type: item.typeName },
      geometry: new ol.geom.Point([Number(item.properties.SMX), Number(item.properties.SMY)])
    })
    style = function () {
      return new ol.style.Style({
        image: new ol.style.Icon({
          scale: 0.6,
          src: item.icon,
          anchor: [0.48, 0.52]
        }),
        text: new ol.style.Text({
          font: 'normal 12px 黑体',
          // // 对其方式
          textAlign: 'center',
          // 基准线
          textBaseline: 'middle',
          offsetY: -35,
          offsetX: 0,
          // 文本填充样式
          fill: new ol.style.Fill({
            color: 'rgba(153,102,51,1)'
          }),
          text: `${item.properties.INDUSTRY_NAME || item.properties.INDUSTRY_NAME || item.properties.VENUE_NAME || item.properties.ORG_NAME || item.properties.FIELD_NAME}`,
          scale: map.getView().getZoom() < 16 ? 0 : 1
        })
      })

    }
    feature.setStyle(style);
    pointLayer.getSource().addFeature(feature);

  });
}
// 定位到用户当前位置
const dingwei = () => {
  const view = map.getView()
  view.animate({
    duration: 850,
    zoom: 14.9,
    center: [prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x] || [36.91976100053535, 117.72139893545783]
    // center: [117.72139893545783, 36.91976100053535]
  })
}
// 邹平地图
const zpLayerMap = () => {
  const view = map.getView()
  view.animate({
    duration: 850,
    zoom: 13.9,
    center: [prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x] || [36.863408, 117.743079]
  })
}
// 绘制圆
const addPoint = () => {
  let metersPerUnit = map.getView().getProjection().getMetersPerUnit()
  // console.log(metersPerUnit);
  let circleRadius = 2000 / metersPerUnit
  let circle = new ol.geom.Circle([Number(prop.dataInfo.userLocation.y), Number(prop.dataInfo.userLocation.x)], circleRadius) // 用圆心和半径确定一个圆
  // eslint-disable-next-line new-cap
  let polygon = new ol.geom.Polygon.fromCircle(circle, 1000) // 得到一个多边形
  // console.log(polygon)
  let arrs = polygon.flatCoordinates.map((e, i) => i % 2 ? null : [polygon.flatCoordinates[i], polygon.flatCoordinates[i + 1]]) // 两两取出
  let list = []
  arrs.map((value) => {
    if (value) {
      list.push(value)
    }
  })
  serchChangDiBySQL(list)
  let feature = new ol.Feature({
    geometry: new ol.geom.Circle([Number(prop.dataInfo.userLocation.y), Number(prop.dataInfo.userLocation.x)], circleRadius),
  })
  feature.setStyle(
    new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(32, 157, 230, 1)'
      }),
    })
  )
  let source = new ol.source.Vector()
  source.addFeature(feature)
  let layer = new ol.layer.Vector({
    opacity: 0.1
  })
  layer.setSource(source)
  map.addLayer(layer)
}
// 查询空间库第一步
const serchChangDiBySQL = (list) => {
  console.log(list);
  console.log(datas.datasetNames);
  // 循环查询数据集
  serchMap(datas.datasetNames[0], list).then(() => {
    serchMap(datas.datasetNames[1], list).then(() => {
      serchMap(datas.datasetNames[2], list).then(() => {
        fixedPoint()
        // serchMap(datas.datasetNames[3], list).then(() => {
        // fixedPoint()
        // })
      })
    })
  })
}
// 查询空间库第二步
const serchMap = (item, list) => {
  return new Promise((resolve, reject) => {
    let geometryParam = new ol.supermap.GetFeaturesByGeometryParameters({
      datasetNames: item,
      geometry: new ol.geom.Polygon([list]),
      spatialQueryMode: 'INTERSECT',
      toIndex: -1
    })
    console.log(geometryParam.geometry);
    new ol.supermap.FeatureService('https://www.zptiyuju.com/iserver/services/data-zpsports/rest/data').getFeaturesByGeometry(geometryParam, function (serviceResult) {
      // console.log(serviceResult.result)
      if (serviceResult.result && serviceResult.result.features.features.length > 0) {
        console.log(serviceResult.result.features.features);
        serviceResult.result.features.features.forEach(element => {
          element.type = item
          if (element.properties.TYPE_ID === '210') {
            element.icon = datas.icon1
            element.typeName = '全民健身路径工程'
          } else if (element.properties.TYPE_ID === '209') {
            element.icon = datas.icon2
            element.typeName = '农民体育健身工程'
          } else if (element.properties.TYPE_ID === '211') {
            element.icon = datas.icon3
            element.typeName = '健身场馆附属设施'
          } else {
            if (item[0] === 'zpgisdb:sys_sportfield_sys_sportfield') {
              // 体育场地
              element.icon = datas.icon4
              element.typeName = '体育场地'
            } else if (item[0] === 'zpgisdb:sys_sportindustry_sys_sportindustry') {
              // 体育产业
              element.icon = datas.icon6
              element.typeName = '体育产业'
            } else {
              // 体育组织
              element.icon = datas.icon5
              element.typeName = '体育组织'
            }
          }
        })
        console.log(serviceResult.result.features.features);
        datas.pointList.push(...serviceResult.result.features.features)
      }
      resolve()
    })
  })
}
//导航
const guide = (signMap) => {
  // let self = this;
  if (self.partnerAddress && self.partnerAddress != '') {
    //景点位置partnerAddress 景点经纬度lng lat
    var lng = self.lng;
    var lat = self.lat;
    if (signMap == 'gd') {
      // 高德地图
      if (customBrowserVersion().android) {
        window.location.href = "androidamap://viewMap?sourceApplication=appname&poiname=" + self.partnerAddress +
          "&lat=" + lat + "&lon=" + lng + "&dev=0";
        //判断是否跳转
        setTimeout(function () {
          let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||
            window.document.webkitHidden
          if (typeof hidden == "undefined" || hidden == false) {
            //调用高德地图
            window.location.href = "https://www.zptiyuju.com/marker?position=" + lng + "," + lat +
              "&name=" + self.partnerAddress;
          }
        }, 2000);
      } else if (customBrowserVersion().ios) {
        window.location.href = "iosamap://viewMap?sourceApplication=appname&poiname=" + self.partnerAddress +
          "&lat=" + lat + "&lon=" + lng + "&dev=0";
        //判断是否跳转
        setTimeout(function () {
          let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||
            window.document.webkitHidden
          if (typeof hidden == "undefined" || hidden == false) {
            //调用高德地图
            window.location.href = "https://www.zptiyuju.com/marker?position=" + lng + "," + lat +
              "&name=" + self.partnerAddress;
          }
        }, 2000);
      }
    } else if (signMap == 'bd') {
      // 百度地图
      if (customBrowserVersion().android) {
        //安卓操作系统
        let d = new Date();
        let t0 = d.getTime();
        window.location.href = "androidamap://viewMap?sourceApplication=appname&poiname=" + self.partnerAddress +
          "&lat=" + lat + "&lon=" + lng + "&dev=0";
        //由于打开需要1～2秒，利用这个时间差来处理－－打开app后，返回h5页面会出现页面变成app下载页面，影响用户体验
        var delay = setInterval(function () {
          var d = new Date();
          var t1 = d.getTime();
          if (t1 - t0 < 3000 && t1 - t0 > 2000) {
            window.location.href = "http://api.map.baidu.com/marker?location=" + lat + "," +
              lng + "&title=" + self.partnerAddress +
              "&content=景点&output=html&src=webapp.baidu.openAPIdemo";
          }
          if (t1 - t0 >= 3000) {
            clearInterval(delay);
          }
        }, 1000);
      }
      if (customBrowserVersion().ios) {
        //ios操作系统
        let d = new Date();
        let t0 = d.getTime();
        window.location.href = "iosamap://viewMap?sourceApplication=appname&poiname=" + self.partnerAddress +
          "&lat=" + lat + "&lon=" + lng + "&dev=0";
        //由于打开需要1～2秒，利用这个时间差来处理－－打开app后，返回h5页面会出现页面变成app下载页面，影响用户体验
        let delay = setInterval(function () {
          var d = new Date();
          var t1 = d.getTime();
          if (t1 - t0 < 3000 && t1 - t0 > 2000) {
            window.location.href = "http://api.map.baidu.com/marker?location=" + lat + "," +
              lng + "&title=" + self.partnerAddress +
              "&content=景点&output=html&src=webapp.baidu.openAPIdemo";
          }
          if (t1 - t0 >= 3000) {
            clearInterval(delay);
          }
        }, 1000);
      }
    }
  } else {
    this.$toast.showToast('暂不知道该景区位置')
  }
}
//区分设备
const customBrowserVersion = () => {
  var u = navigator.userAgent;
  return {
    trident: u.indexOf('Trident') > -1, //IE内核
    presto: u.indexOf('Presto') > -1, //opera内核
    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
    iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, //是否iPad
    webApp: u.indexOf('Safari') == -1, //是否web应用程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) == " qq", //是否QQ
    isBaidu: u.indexOf('baiduboxapp') !== -1, //是否为百度浏览器
    isqqBrowser: u.indexOf('mqqbrowser') !== -1, //是否为qq浏览器
    isWxBrowser: u.indexOf('micromessenger') !== -1, //是否为微信浏览器
    isUc: u.indexOf('ucbrowser') !== -1, //是否为uc浏览器
  };
}

const srcClick = (item) => {
  console.log(item);
  datas.src = baseUrl + item
  datas.showModalImg = true
}
// 首先判断是否是在市区内
const getZhen = () => {
  let geometryParam = new ol.supermap.GetFeaturesByGeometryParameters({
    datasetNames: ['zpgisdb2:zoupingzhenji'],
    geometry: new ol.geom.Point([prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x]),
    // geometry: new ol.geom.Point([117.743056, 36.860244]),
    spatialQueryMode: 'INTERSECT',
    toIndex: -1
  })
  new ol.supermap.FeatureService('https://www.zptiyuju.com/iserver/services/data-zpsports/rest/data').getFeaturesByGeometry(geometryParam, function (serviceResult) {
    // console.log(serviceResult.result.features.features[0].properties.乡);
    if (serviceResult.result.features.features[0] && (serviceResult.result.features.features[0].properties.乡 === '黄山街道' || serviceResult.result.features.features[0].properties.乡 === '高新街道' || serviceResult.result.features.features[0].properties.乡 === '黛溪街道')) {
      console.log('在市区');
      //  在市区内
      let geometryParam2 = new ol.supermap.GetFeaturesBySQLParameters({
        datasetNames: ['zpgisdb2:zoupingzhenji'],
        queryParameter: {
          name: 'zoupingzhenji',
          attributeFilter: "乡='黄山街道' or 乡='高新街道' or 乡='黛溪街道'",
        },
        toIndex: -1
      })
      new ol.supermap.FeatureService('https://www.zptiyuju.com/iserver/services/data-zpsports/rest/data').getFeaturesBySQL(geometryParam2, function (serviceResult2) {
        console.log(serviceResult2.result.features.features);
        let arrs = []
        serviceResult2.result.features.features.forEach(item => {
          console.log(item.geometry.coordinates);
          arrs.push(...item.geometry.coordinates)
        })
        serviceResult2.result.features.features[0].geometry.coordinates = arrs
        let arr = []
        arrs.forEach(a => {
          arr.push(...a)
        })
        serchChangDiBySQL([...arr[0], ...arr[1], ...arr[2]])
      })
    } else {
      console.log('不在市区');
      // 不在市区内
      addPoint()
    }
  })
}

onMounted(() => {
  initMap()
  // console.log(prop.dataInfo.userLocation);
  getZhen()
})

</script>
<style lang="less" scoped>
#overMap {
  position: absolute;
}

.contentPopup {
  min-width: 400px;
}

.change {
  padding: 5px;
  position: absolute;
  left: 10px;
  bottom: 25px;
  border-radius: 7px;
  background-color: rgb(127, 157, 255);
  color: #fff;
}

.modalImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

#popups {
  min-width: 400px;
  max-width: 650px;
  min-height: 240px;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(101, 101, 101, 0.5);
  border-radius: 8px;
  padding: 18px;

  img {
    width: 100px;
    height: 140px;
    margin-right: 14px;
    margin-bottom: 10px;
  }

  .popupBox1 {
    display: flex;

    .t1 {
      font-size: 20px !important;
      color: #1a1a1a;
      // margin: 0;
      margin-bottom: 5px;
    }

    .t2 {
      color: #707070;
      font-size: 13px;
      // margin: 0;
      margin-bottom: 14px;
    }

    .t3 {
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 0.5px 4px;
      font-size: 12px;
      color: #acacac;

      .icon {
        width: 33px;
        height: 33px;
      }
    }
  }

  .popupBox2 {
    font-size: 10px;
    color: #777777;
  }
}
</style>
