import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import fitnessCircle from "../views/fitnessCircle.vue";
import demo from "../views/demo.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/fitnessCircle",
    name: "FitnessCircle",
    component: fitnessCircle,
  },
  {
    path: "/demo",
    name: "demo",
    component: demo,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
