<template>
  <!-- 体育场地、体育组织、体育产业、体育设施 -->
  <sport
    v-if="datas.dataInfo.data && (datas.dataInfo.type === '1' || datas.dataInfo.type === '2' || datas.dataInfo.type === '3')"
    :dataInfo="datas.dataInfo" :state="datas.pitchSS"></sport>
  <!-- 十分钟健身圈 -->
  <FitnessCircle v-else-if="datas.dataInfo.type === '6'" :dataInfo="datas.dataInfo"></FitnessCircle>
  <!-- 器材采集标注地图 -->
  <LableMap v-else-if="datas.dataInfo.type === '10'" :dataInfo="datas.dataInfo"></LableMap>

  <!-- 体育场地、体育设施切换 -->
  <!-- <div class="changeBox" v-if="datas.dataInfo.data && datas.dataInfo.type === '1'">
    <div @click="changeSelect(1)" :class="{ red: datas.pitchCD }">体育场地</div>
    <div @click="changeSelect(2)" :class="{ red: datas.pitchSS }">设施分布</div>
  </div> -->
</template>

<script setup>
import { toRaw, ref, reactive } from '@vue/reactivity'
import config from '../config/index';
import sport from './sport.vue';
import { onMounted, onBeforeMount, onDeactivated } from 'vue'
import { getSportfieldListNotdel, getSportindustryListNotdel, getSportorgListNotdel, getVenueListNotDel } from '../api/home';
import { useRouter } from 'vue-router'
import FitnessCircle from './fitnessCircle.vue';
import LableMap from './lableMap.vue';
let dataInfo = reactive({})
const router = useRouter()
let datas = reactive({
  dataInfo: {},
  pitchSS: false,
  pitchCD: true
})


onMounted(() => {

  // console.log(config.baseUrl.pro);
  // const a = JSON.parse(decodeURIComponent('%7B%22token%22%3A%22eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl93eHVzZXJfa2V5IjoiMjk2M2ViZWItY2I4Ny00M2E4LWE0YTItMjZlYjc2NjU3ZTBlIn0.6CjH-DKb7wa8UdJdBIXh_v64sGzivi00YV8yVfRM-uQKpWAZ6wIBvodcfM4MIm4obKsUBQTBubO_ghJ0lmvkVg%22%2C%22userLocation%22%3A%7B%22x%22%3A36.66645%2C%22y%22%3A117.07641%7D%2C%22tokenType%22%3A%22Wx%22%2C%22type%22%3A%226%22%7D'))
  // datas.dataInfo = toRaw(a)
  // console.log(datas.dataInfo);
  // localStorage.setItem('token', datas.dataInfo.tokenType + ' ' + datas.dataInfo.token)
  // switch (datas.dataInfo.type) {
  //   case '1':
  //     getList(getSportfieldListNotdel)
  //     document.title = '体育场地'
  //     break;
  //   case '2':
  //     getList(getSportorgListNotdel)
  //     document.title = '体育组织'
  //     break;
  //   case '3':
  //     getList(getSportindustryListNotdel)
  //     document.title = '体育产业'
  //     break;
  // }




  if (router.currentRoute.value.query.dataInfo) {
    const a = JSON.parse(decodeURIComponent(router.currentRoute.value.query.dataInfo))
    // console.log(a);
    datas.dataInfo = toRaw(a)
    // 体育产业、场所、组织
    localStorage.setItem('token', datas.dataInfo.tokenType + ' ' + datas.dataInfo.token)
    switch (datas.dataInfo.type) {
      case '1':
        getList(getSportfieldListNotdel)
        document.title = '体育场地'
        break;
      case '2':
        getList(getSportorgListNotdel)
        document.title = '体育组织'
        break;
      case '3':
        getList(getSportindustryListNotdel)
        document.title = '体育产业'
        break;
    }
  } else if (router.currentRoute.value.query.fitnessCircleInfo) {
    // 十分钟健身圈
    const a = JSON.parse(decodeURIComponent(router.currentRoute.value.query.fitnessCircleInfo))
    console.log(a);
    datas.dataInfo = toRaw(a)
    localStorage.setItem('token', datas.dataInfo.tokenType + ' ' + datas.dataInfo.token)
    document.title = '十分钟健身圈'
  } else if (router.currentRoute.value.query.userLocation) {
    // 器材采集、巡检
    const a = JSON.parse(decodeURIComponent(router.currentRoute.value.query.userLocation))
    datas.dataInfo = toRaw(a)
    // datas.dataInfo.type = '10'
    localStorage.setItem('token', datas.dataInfo.tokenType + ' ' + datas.dataInfo.token)
    document.title = '器材标注'
  }


})
// 销毁页面时清除token
onDeactivated(() => {
  localStorage.removeItem('token')
})
// 切换  场馆、设施分布
const changeSelect = async (num) => {
  if (num === 1) {
    getList(getSportfieldListNotdel)
    datas.pitchCD = true
    datas.pitchSS = false
  } else {
    getList(getVenueListNotDel)
    datas.pitchCD = false
    datas.pitchSS = true
  }
}
// 获取数据
const getList = async (api) => {
  // datas.dataInfo.data = null
  const res = await api()
  console.log(res.data.rows);
  res.data.rows.forEach((item) => {
    item.images = config.baseUrl.pro + item.images
  })
  datas.dataInfo.data = res.data.rows
  datas.dataInfo.token = null
}
</script>
<style lang="less" scoped>
#overMap {
  position: absolute;
}

.change {
  padding: 5px;
  position: absolute;
  left: 10px;
  bottom: 25px;
  border-radius: 7px;
  background-color: rgb(127, 157, 255);
  color: #fff;
}

#popups {
  width: 550px;
  min-height: 240px;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(101, 101, 101, 0.5);
  border-radius: 8px;
  padding: 18px;

  .popupBox1 {
    display: flex;
    margin-bottom: 14px;

    img {
      width: 120px;
      height: 150px;
      margin-right: 14px;
    }

    .t1 {
      // font-size: 100px;
      font-size: 20px !important;
      color: #1a1a1a;
      // margin: 0;
      margin-bottom: 5px;
    }

    .t2 {
      color: #707070;
      font-size: 13px;
      // margin: 0;
      margin-bottom: 14px;
      // transform: scale(0.7);
    }

    .t3 {
      // border: 1px solid #dcdcdc;
      display: flex;
      align-items: center;
      border-radius: 6px;
      // display: inline-block;
      padding: 0.5px 4px;
      font-size: 12px;
      color: #acacac;

      .icon {
        width: 33px;
        height: 33px;
      }
    }
  }

  .popupBox2 {
    font-size: 10px;
    color: #777777;
  }
}

.changeBox {
  position: absolute;
  left: 30px;
  top: 20px;
  width: 300px;
  height: 66px;
  line-height: 66px;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 7px 0px rgba(57, 57, 57, 0.21);
}

.red {
  color: rgba(15, 215, 151, 1);
  font-weight: 700;
}
</style>
