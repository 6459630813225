<!-- 器材标注 -->
<template>
  <div>
    <div id="overMap" style="width: 100%;height:100%"></div>
    <div class="baocun" @click="saveSubmit">保存</div>
  </div>
</template>

<script setup>
import { toRaw, ref, reactive } from '@vue/reactivity'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import config from '../config/index';
// // import { getSportfieldListNotdel } from '../api/home';
let map = null
let select = null
let popup // 地图上的弹框图层
let layerType = ref('vec')
let baseUrl = config.baseUrl.pro
let popupInfo = ref(null)
let iconImg = require('../assets/images/ding.png')
let dataInfo = reactive({})
let datas = reactive({
  coordinate: []
})
// var feature // 全局map地图打点实例
var pointLayer = new ol.layer.Vector({
  source: new ol.source.Vector({
    features: []
  })
})
const prop = defineProps({
  dataInfo: Object
})
// 初始化地图
const initMap = () => {
  // 初始化地图
  map = new ol.Map({
    target: 'overMap',
    controls: ol.control.defaults({ attribution: false, zoom: false }),
    view: new ol.View({
      center: [117.73714744925228, 36.861579290645864],
      zoom: 12,
      projection: 'EPSG:4326',
      multiWorld: true
    }),
    layers: [
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          key: 'a23c76b3ad3bd71b88f0669ab26087c7'
        })
      }),
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          isLabel: true,
          key: 'a23c76b3ad3bd71b88f0669ab26087c7'
        })
      }),
      pointLayer
    ]
  })
  var feature = new ol.Feature({
    geometry: new ol.geom.Point([prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x])
  })
  let style = new ol.style.Style({
    image: new ol.style.Icon({
      scale: 0.6,
      src: iconImg,
      anchor: [0.48, 0.52]
    })
  })
  feature.setStyle(style);
  pointLayer.getSource().addFeature(feature);
  // fixedPoint()
  // 初始化添加点击点位信息后展示的弹出框
  // popup = new ol.Overlay({
  //   element: document.getElementById('popups'),
  //   offset: [15, -110]
  // })
  // map.addOverlay(popup)
  // popup.setPosition(undefined)
  dingwei()
  map.on('click', function (params) {
    console.log(params.coordinate);

    mapClick(params)
  })
}
const dingwei = () => {
  console.log([prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x]);
  const view = map.getView()
  view.animate({
    duration: 0,
    zoom: 15,
    center: prop.dataInfo.userLocation.y ? [prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x] : [117.73700798712731, 36.86171030624916]
  })
}
const mapClick = (params) => {
  datas.coordinate = params.coordinate
  map.removeLayer(pointLayer)
  pointLayer = new ol.layer.Vector({
    source: new ol.source.Vector({
      features: []
    })
    // zIndex: 999
  })
  var feature = new ol.Feature({
    geometry: new ol.geom.Point(params.coordinate)
  })
  let style = new ol.style.Style({
    image: new ol.style.Icon({
      scale: 0.6,
      src: iconImg,
      anchor: [0.48, 0.52]
    })
  })
  feature.setStyle(style);
  pointLayer.getSource().addFeature(feature);
  map.addLayer(pointLayer)
}
const saveSubmit = () => {
  console.log(datas.coordinate);
  wx.miniProgram.postMessage({ data: datas.coordinate })
  wx.miniProgram.navigateBack()
}


onMounted(() => {
  initMap()
})

</script>
<style lang="less" scoped>
#overMap {
  position: absolute;
}

.baocun {
  width: 130px;
  height: 60px;
  position: absolute;
  right: 25px;
  bottom: 25px;
  text-align: center;
  line-height: 60px;
  border-radius: 7px;
  font-size: 18px;
  background-color: rgb(127, 157, 255);
  color: #fff;
}
</style>
