<template>
  <div id="overMap" style="width: 100%;height:100%"></div>
  <div class="change" @click="changeTianditu"><img style="width:40px;height:40px" src="../assets/images/qiehuan.png">
  </div>
  <div class="dingwei" @click="dingwei"><img style="width:40px;height:40px" src="../assets/images/dangqian.png"></div>
  <div id="popups">
    <div class="popupBox1">
      <img v-if="popupInfo?.icon" @click="srcClick(popupInfo?.icon[0])" :src="popupInfo?.icon[0]">
      <img
        v-else-if="popupInfo?.images && !prop.state && popupInfo?.images[0] !== 'https://www.zptiyuju.com/apiundefined' && popupInfo?.images[0] !== 'https://www.zptiyuju.com/api' && popupInfo?.images[0] !== 'https://www.zptiyuju.com/apinul'"
        :src="popupInfo?.images[0]" @click="srcClick(popupInfo?.images[0])">
      <img v-else src="../assets/images/nullImg.png" />
      <div>
        <div class="t1">{{ popupInfo?.fieldName || popupInfo?.venueName || popupInfo?.orgName || popupInfo?.industryName
        }}
        </div>
        <div class="t2"><img style="width: 18px;height: 20px;margin-right: 3px;" src="../assets/images/weizhi.png" />
          {{ popupInfo?.location || popupInfo?.municipality + popupInfo?.district + popupInfo?.neighborhood +
            popupInfo?.street }}<span v-if="popupInfo?.isBusiness">（{{ popupInfo?.isBusiness === '0' ? '关门' : '营业'
  }}）</span>
        </div>
        <div class="t3 type" v-if="popupInfo?.typeId">
          <img style="width: 18px;height: 20px;margin-right: 6px;" src="../assets/images/type.png" />
          {{ popupInfo?.typeId === 209 ? '农民体育健身工程' : popupInfo?.typeId === 210 ? '全民健身路径工程' : '健身场馆附属设施' }}
        </div>
        <div class="t3" v-if="popupInfo?.tag">
          {{ popupInfo?.tag }}
        </div>
        <div class="t3" v-if="popupInfo?.type">
          {{ popupInfo?.type }}
        </div>
        <div class="t3">
          {{ popupInfo?.des ? popupInfo?.des : '' }}
        </div>
        <div class="t3" v-if="popupInfo?.industryPhone">
          联系电话：{{ popupInfo?.industryPhone }}
        </div>
        <div class="t3" v-if="popupInfo?.businessTime">
          <img class="icon" src="../assets/images/yingye.png" />
          {{ popupInfo?.businessTime }}
        </div>
      </div>
    </div>

    <div class="popupBottom">
      <div class="popupBox2" v-if="popupInfo?.openness">
        开放状态：{{ popupInfo?.openness }}
      </div>
      <div class="popupBox2" v-if="popupInfo?.streets">
        所属镇街道：{{ popupInfo?.streets }}
      </div>
      <div class="popupBox2" v-if="popupInfo?.neighborhood">
        居委会：{{ popupInfo?.neighborhood }}
      </div>
      <div class="popupBox2" v-if="popupInfo?.maintenance">
        维护单位：{{ popupInfo?.maintenance }}
      </div>
      <div class="popupBox2" v-if="true">
        {{ popupInfo?.remark }}
      </div>
    </div>
    <div class="imgBox">
      <template
        v-if="popupInfo?.images && popupInfo?.images[0] !== 'https://www.zptiyuju.com/apinull' && popupInfo?.images[0] !== 'https://www.zptiyuju.com/apiundefined' && popupInfo?.images[0] !== 'https://www.zptiyuju.com/api'">
        <img v-for="item in popupInfo?.images" :key="item" :src="item" @click="srcClick(item)">
      </template>
      <template
        v-else-if="popupInfo?.icon && popupInfo?.icon[0] !== 'https://www.zptiyuju.com/apinull' && popupInfo?.icon[0] !== 'https://www.zptiyuju.com/api'">
        <img v-for="item in popupInfo?.icon" :key="item" :src="item" @click="srcClick(item)">
      </template>
      <template v-else>
        <img src="../assets/images/nullImg.png" />
      </template>
    </div>
    <!-- <div class="venue"
         v-if="popupInfo?.venueName">
      <div style="font-size: 20px;font-weight: 700;color:#333;">{{popupInfo?.venueName}}</div>
      <div><span>设施类型：</span>{{popupInfo?.typeId===209?'农民体育健身工程':popupInfo?.typeId===210?'全民健身路径工程':'健身场馆附属设施'}}</div>
      <div><span>具体地址：</span>{{popupInfo?.municipality+popupInfo?.district+popupInfo?.neighborhood+popupInfo?.street}}</div>
      <div><span>居委会：</span> {{popupInfo?.neighborhood}}</div>
      <div><span>维护单位：</span> {{popupInfo?.maintenance}}</div>
      <div><span>开放状态：</span>{{popupInfo?.openness}}</div>
    </div> -->
  </div>
  <!-- <div class="zhuji" v-if="prop.state">
    <div><img :src="datas.icon1">全民健身路径工程</div>
    <div><img :src="datas.icon2">农民体育健身工程</div>
    <div><img :src="datas.icon3">健身场馆附属设施</div>
  </div> -->
  <!-- 图片大图弹框 -->
  <div class="modalImg" v-if="datas.showModalImg" @click="datas.showModalImg = false">
    <img :src="datas.src">
  </div>
</template>

<script setup>
import { message, Button } from 'ant-design-vue';
import { reactive, toRefs, ref, onMounted, onUpdated, defineProps, onDeactivated, watch } from 'vue'
import config from '../config/index';
import { useRouter } from 'vue-router'
const userIconImg = require('../assets/images/ding.png')
const router = useRouter()
// // import { getSportfieldListNotdel } from '../api/home';
let map = null
let select = null
let popup // 地图上的弹框图层
let layerType = ref('vec')
let baseUrl = config.baseUrl.pro
let popupInfo = ref(null)
let iconImg
// let dataInfo = reactive({})
// var feature // 全局map地图打点实例
var pointLayer = null
var pointLayerUser = new ol.layer.Vector({
  source: new ol.source.Vector({
    features: []
  }),
  zIndex: 999
})
// 邹平市图层
let mianLayerSHI = new ol.layer.Tile({
  source: new ol.source.TileSuperMapRest({
    url: 'https://www.zptiyuju.com/iserver/services/map-zpsports/rest/maps/zoupingxianji@zpgisdb',
    wrapX: true
  }),
  visible: true,
  zIndex: 1,
  projection: 'EPSG:4326'
})
// 镇图层
let mianLayerZHEN = new ol.layer.Tile({
  source: new ol.source.TileSuperMapRest({
    url: 'https://www.zptiyuju.com/iserver/services/map-zpsports/rest/maps/zoupingzhenji@zpgisdb',
    wrapX: true
  }),
  visible: true,
  zIndex: 9,
  projection: 'EPSG:4326'
})
// 矢量图
let mianLayerSHILIANG = new ol.layer.Tile({
  source: new ol.source.TileSuperMapRest({
    url: 'http://runto.tpddns.net:8090/iserver/services/map-tianditu/rest/maps/矢量底图_经纬度',
    wrapX: true
  }),
  visible: true,
  zIndex: 9,
  projection: 'EPSG:4326'
})
// 矢量图文字
let mianLayerSHILIANGWENZI = new ol.layer.Tile({
  source: new ol.source.TileSuperMapRest({
    url: 'http://runto.tpddns.net:8090/iserver/services/map-tianditu/rest/maps/矢量中文注记_经纬度',
    wrapX: true
  }),
  visible: true,
  zIndex: 9,
  projection: 'EPSG:4326'
})
const datas = reactive({
  dataInfoFormatting: [],
  src: '',
  showModalImg: false,
  iconState: false,
  icon1: require('../assets/images/nongmin.png'),
  icon2: require('../assets/images/jianshensheshi.png'),
  icon3: require('../assets/images/fushusheshi.png'),
})
const prop = defineProps({
  dataInfo: Object,
  state: Boolean
})
watch(
  () => prop.dataInfo.data,
  (newVal, oldVal) => {
    map.removeLayer(pointLayer)
    pointLayer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: []
      }),
      style: function (feature, resolution) {
        const typeid = feature.values_.features[0].values_.attributes.type
        var size = feature.get('features').length;
        if (size < 2) {
          if (typeid.typeId && prop.dataInfo.type === '1') {
            if (typeid.typeId === 210) {
              iconImg = datas.icon1
            } else if (typeid.typeId === 209) {
              iconImg = datas.icon2
            } else if (typeid.typeId === 211) {
              iconImg = datas.icon3
            }
          }
          return new ol.style.Style({
            image: new ol.style.Icon({
              scale: 0.6,
              src: iconImg,
              // anchor: [0.48, 0.52]
            }),
            text: new ol.style.Text({
              font: 'normal 12px 黑体',
              // // 对其方式
              textAlign: 'center',
              // 基准线
              textBaseline: 'middle',
              offsetY: -35,
              offsetX: 0,
              // 文本填充样式
              fill: new ol.style.Fill({
                color: 'rgba(153,102,51,1)'
              }),
              text: `${typeid.sportmapName || typeid.venueName || typeid.fieldName}`,
              scale: map.getView().getZoom() < 16 ? 0 : 1
            })
          })
        }
        else {
          return new ol.style.Style({
            image: new ol.style.Circle({
              radius: 30,
              stroke: new ol.style.Stroke({
                color: 'white'
              }),
              fill: new ol.style.Fill({
                color: '#7EC0EE'
              })
            }),
            text: new ol.style.Text({
              text: size.toString(),
              fill: new ol.style.Fill({
                color: 'white'
              })
            })
          })
        }
      },
      zIndex: 999
    })
    if (prop.dataInfo.type === '1') {
      iconImg = require('../assets/images/changditubiao.png')
    } else if (prop.dataInfo.type === '2') {
      iconImg = require('../assets/images/zuzhitubiao.png')
    } else if (prop.dataInfo.type === '3') {
      iconImg = require('../assets/images/ticai.png')
    }
    datas.dataInfoFormatting = prop.dataInfo.data
    popup.setPosition(undefined)
    console.log(datas.dataInfoFormatting);
    fixedPoint()
  }
)

// 初始化地图
const initMap = () => {
  pointLayer = new ol.layer.Vector({
    source: new ol.source.Vector({
      features: []
    }),
    style: function (feature, resolution) {
      // console.log(feature.values_.features[0].values_.attributes.type.typeId);
      const typeid = feature.values_.features[0].values_.attributes.type
      var size = feature.get('features').length;
      if (size < 2) {
        if (typeid.typeId && prop.dataInfo.type === '1') {
          if (typeid.typeId === 210) {
            iconImg = datas.icon1
          } else if (typeid.typeId === 209) {
            iconImg = datas.icon2
          } else if (typeid.typeId === 211) {
            iconImg = datas.icon3
          }
        }
        return new ol.style.Style({
          image: new ol.style.Icon({
            scale: 0.6,
            src: iconImg,
            anchor: [0.48, 0.52]
          }),
          text: new ol.style.Text({
            font: 'normal 12px 黑体',
            // // 对其方式
            textAlign: 'center',
            // 基准线
            textBaseline: 'middle',
            offsetY: -35,
            offsetX: 0,
            // backgroundFill: new ol.style.Stroke({
            //   color: 'rgba(42,63,181,0.3)',
            // }),
            // 文本填充样式
            fill: new ol.style.Fill({
              color: 'rgba(153,102,51,1)'
            }),
            // padding: [2, 2, 2, 2],
            text: `${typeid.sportmapName || typeid.venueName || typeid.fieldName}`,
            scale: map.getView().getZoom() < 16 ? 0 : 1
          })
        })
      }
      else {
        return new ol.style.Style({
          image: new ol.style.Circle({
            radius: 30,
            stroke: new ol.style.Stroke({
              color: 'white'
            }),
            fill: new ol.style.Fill({
              color: '#7EC0EE'
            })
          }),
          text: new ol.style.Text({
            text: size.toString(),
            fill: new ol.style.Fill({
              color: 'white'
            })
          })
        })
      }
    },
    zIndex: 999
  })
  // 初始化地图
  map = new ol.Map({
    target: 'overMap',
    controls: ol.control.defaults({ attribution: false, zoom: true }),
    view: new ol.View({
      center: [117.73714744925228, 36.861579290645864],
      zoom: 12,
      projection: 'EPSG:4326',
      multiWorld: true
    }),
    layers: [
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          key: 'a23c76b3ad3bd71b88f0669ab26087c7'
        })
      }),
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          isLabel: true,
          key: 'a23c76b3ad3bd71b88f0669ab26087c7'
        })
      }),
      // mianLayerSHILIANG,
      // mianLayerSHILIANGWENZI,
      mianLayerSHI,
      mianLayerZHEN
    ]
  })
  // 初始化添加点击点位信息后展示的弹出框
  popup = new ol.Overlay({
    element: document.getElementById('popups'),
    offset: [15, -110]
  })
  map.addOverlay(popup)
  popup.setPosition(undefined)
  var feature = new ol.Feature({
    geometry: new ol.geom.Point([Number(prop.dataInfo.userLocation.y), Number(prop.dataInfo.userLocation.x)])
  })
  let style = new ol.style.Style({
    image: new ol.style.Icon({
      scale: 0.6,
      src: userIconImg,
      anchor: [0.48, 0.52]
    })
  })
  feature.setStyle(style);
  pointLayerUser.getSource().addFeature(feature);
  if (prop.dataInfo.userLocation.y) {
    map.addLayer(pointLayerUser)
  }
  // map.on('click', function (e) {
  //   console.log(e);
  // })
  fixedPoint()
}
// 天地图矢量图和影像图切换
const changeTianditu = () => {
  layerType.value = layerType.value === 'vec' ? 'img' : 'vec'
  map.getLayers().item(0).setSource(
    new ol.source.Tianditu({
      layerType: layerType.value,
      key: 'd63019edbae6824c3518338d20226c7e',
      projection: 'EPSG:4326',
      wrapX: false
    })
  )
  map.getLayers().item(1).setSource(
    new ol.source.Tianditu({
      layerType: layerType.value,
      key: 'd63019edbae6824c3518338d20226c7e',
      projection: 'EPSG:4326',
      isLabel: true,
      wrapX: false
    })
  )
}
// // 地图打点
const fixedPoint = () => {
  select && map.removeInteraction(select);
  let style
  let sources = new ol.source.Vector();
  datas.dataInfoFormatting.forEach((item, index) => {
    var feature = new ol.Feature({
      geometry: new ol.geom.Point([Number(item.lng), Number(item.lat)]),
      attributes: { type: item }
    })
    sources.addFeature(feature);
    style = function () {
      if (item.typeId && prop.dataInfo.type === '1') {
        if (item.typeId === 210) {
          iconImg = datas.icon1
        } else if (item.typeId === 209) {
          iconImg = datas.icon2
        } else if (item.typeId === 211) {
          iconImg = datas.icon3
        }
      }
      const style1 = new ol.style.Style({
        image: new ol.style.Icon({
          scale: 0.6,
          src: iconImg,
          anchor: [0.48, 0.52]
        }),
        text: new ol.style.Text({
          font: 'normal 12px 黑体',
          // // 对其方式
          textAlign: 'center',
          // 基准线
          textBaseline: 'middle',
          offsetY: -35,
          offsetX: 0,
          // 文本填充样式
          fill: new ol.style.Fill({
            color: 'rgba(153,102,51,1)'
          }),
          text: `${item.sportmapName || item.venueName || item.fieldName}`,
          scale: map.getView().getZoom() < 16 ? 0 : 1
        })
      })
      return style1
    }
    feature.setStyle(style);
    pointLayer.getSource().addFeature(feature);

  });
  // 聚合
  var cluster = new ol.source.Cluster({
    source: sources,
    distance: 0,
    minDistance: 0
  })
  pointLayer.setSource(cluster)
  // console.log(pointLayer);
  select = new ol.interaction.Select({
    layers: [pointLayer],
  });
  map.addInteraction(select)
  map.addLayer(pointLayer)
  // cluster.un(select)
  // 首次切换先加载一次 监听地图缩放
  if (map.getView().getZoom() === map.getView().getMaxZoom()) {
    cluster.setDistance(0);
  }
  else {
    cluster.setDistance(30);
  }
  map.on('moveend', function (event) {
    if (map.getView().getZoom() === map.getView().getMaxZoom()) {
      cluster.setDistance(0);
    }
    else {
      cluster.setDistance(30);
    }
  });
  // console.log(pointLayer.getSource.);
  // map.removeInteraction(select)
  select.on('select', function (e) {
    // console.log(e);
    // console.log(pointLayer);
    if (e.selected.length > 0 && e.selected[0].values_.features.length === 1) {
      const flatCoordinates = e.selected[0].values_.geometry.flatCoordinates  //坐标位置
      console.log(e.selected[0].values_.geometry.flatCoordinates);
      popupInfo.value = {}
      prop.dataInfo.data.forEach(item => {
        if (flatCoordinates[0] === item.lng && flatCoordinates[1] === item.lat) {
          popupInfo.value = item
          console.log(item);
          // if (popupInfo.value.icon && popupInfo.value.icon.indexOf('https://www.zptiyuju.com') === -1) {
          //   datas.iconState = true
          //   popupInfo.value.icon = baseUrl + popupInfo.value.icon
          //   popupInfo.value.images = null
          // }
          if (!Array.isArray(popupInfo.value.images)) {
            console.log(popupInfo.value.icon);
            if (popupInfo.value.images) {
              popupInfo.value.images = popupInfo.value.images.replaceAll('https://www.zptiyuju.com/api', '')
              popupInfo.value.images = popupInfo.value.images.split(',')
              popupInfo.value.images = popupInfo.value.images.map(item => baseUrl + item)
            }
          }
          if (!Array.isArray(popupInfo.value.icon)) {
            console.log(popupInfo.value.icon);
            if (popupInfo.value.icon) {
              popupInfo.value.icon = popupInfo.value.icon.replaceAll('https://www.zptiyuju.com/api', '')
              popupInfo.value.icon = popupInfo.value.icon.split(',')
              popupInfo.value.icon = popupInfo.value.icon.map(item => baseUrl + item)
            }
          }
        }
      })
      var feature = e.selected[0]
      // const coordinate = e.mapBrowserEvent.coordinate // 获取坐标
      // popup.setPosition(coordinate)
      console.log(popupInfo.value);
      popup.setPosition(feature.getGeometry().getCoordinates())
    } else if (popup) {
      console.log(123);
      // popupInfo.value = {}
      console.log(e.mapBrowserEvent.coordinate);
      // if (e.selected.length > 0) message.warning('此处有多个点位，请放大地图后查看！', 2);
      popup.setPosition(undefined)
      if (e.selected.length > 0) unfold(e.mapBrowserEvent.coordinate)
    }
  });
  dingwei()

}
const dingwei = () => {
  console.log([prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x]);
  const view = map.getView()
  view.animate({
    duration: 0,
    zoom: 14.5,
    center: prop.dataInfo.userLocation.y ? [prop.dataInfo.userLocation.y, prop.dataInfo.userLocation.x] : [117.73700798712731, 36.86171030624916]
  })
}
const unfold = (center) => {
  const view = map.getView()
  view.animate({
    duration: 200,
    zoom: 16.5,
    center: center
  })
}
const srcClick = (item) => {
  console.log(item);
  datas.src = item
  datas.showModalImg = true
}

onMounted(() => {
  console.log(prop.dataInfo);
  if (prop.dataInfo.type === '1') {
    iconImg = require('../assets/images/changditubiao.png')
  } else if (prop.dataInfo.type === '2') {
    iconImg = require('../assets/images/zuzhitubiao.png')
  } else if (prop.dataInfo.type === '3') {
    iconImg = require('../assets/images/ticai.png')
  }
  datas.dataInfoFormatting = prop.dataInfo.data
  console.log(datas.dataInfoFormatting);
  // formattingList()
  initMap()
  // console.log('2.-组件挂载到页面之后执行-------onMounted')
})
onUpdated(() => {
  console.log('3.-组件更新之后执行-------onUpdated')
})
// 销毁页面时
onDeactivated(() => {
  map = null
})
</script>

<style scoped lang='less'>
#overMap {
  position: absolute;
}

.modalImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

.change {
  padding: 5px;
  position: absolute;
  right: 20px;
  bottom: 85px;
  border-radius: 7px;
  color: #fff;

  box-shadow: 0px 0px 6px 1px rgba(68, 68, 68, 0.7);
}

.dingwei {
  padding: 5px;
  position: absolute;
  right: 20px;
  bottom: 185px;
  border-radius: 7px;
  color: #fff;
  box-shadow: 0px 0px 6px 1px rgba(70, 70, 70, 0.7);
}

#popups {
  min-width: 550px;
  max-width: 620px;
  min-height: 240px;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 10px 0px rgba(101, 101, 101, 0.5);
  border-radius: 8px;
  padding: 18px;

  .popupBox1 {
    display: flex;
    margin-bottom: 14px;

    img {
      width: 150px;
      height: 200px;
      margin-right: 14px;
    }

    .t1 {
      font-size: 23px;
      font-size: 23px !important;
      color: #1a1a1a;
      margin-bottom: 5px;
    }

    .t2 {
      color: #16bb89;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .t3 {
      display: flex;
      align-items: center;
      border-radius: 6px;
      font-size: 12px;
      color: #acacac;

      .icon {
        width: 33px;
        height: 33px;
      }
    }

  }

  .popupBottom {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;

    .popupBox2 {
      min-width: 50%;
      font-size: 10px;
      color: #777777;
    }
  }

  .imgBox {
    margin-top: 20px;
    border-top: 1px solid rgb(218, 218, 218);
    padding-top: 20px;

    img {
      width: 100px;
      height: 140px;
      margin-right: 14px;
      margin-bottom: 10px;
    }
  }
}

.venue div {
  // border-bottom: 1px solid rgb(224, 224, 224);
  margin-bottom: 10px;

  span {
    font-weight: 700;
    color: #383838;
  }
}

.venue div:nth-child(1) {
  margin-bottom: 20px;
}

.zhuji {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  div {
    margin-bottom: 5px;
    font-size: 12px;

    img {
      width: 40px;
      height: 45px;
      margin-right: 3px;
    }
  }
}

/deep/.ol-zoom {
  position: absolute;
  top: 110px;
}
</style>