<template>
  <div id="map"></div>
</template>

<script setup>
import { reactive, toRefs, onMounted, onUpdated } from 'vue'
const datas = reactive({})
const prop = defineProps({
})
var map
var source
var layer = new ol.layer.Vector({
  source: new ol.source.Vector({
    features: []
  })
  // zIndex: 999
})
onMounted(() => {
  console.log('2.-组件挂载到页面之后执行-------onMounted')
  init()
})
onUpdated(() => {
})
const init = () => {

  // 随机创建1000个要素
  source = new ol.source.Vector();
  for (var i = 1; i <= 200; i++) {
    var coordinates = [120.00 + Math.random(), 30.00 + Math.random()];
    var feature = new ol.Feature(new ol.geom.Point(coordinates));
    source.addFeature(feature);
  }
  for (var i = 1; i <= 200; i++) {
    var coordinates = [120.01 + Math.random(), 30.01 + Math.random()];
    var feature = new ol.Feature(new ol.geom.Point(coordinates));
    source.addFeature(feature);
  }
  for (var i = 1; i <= 200; i++) {
    var coordinates = [120.02 + Math.random(), 30.02 + Math.random()];
    var feature = new ol.Feature(new ol.geom.Point(coordinates));
    source.addFeature(feature);
  }
  for (var i = 1; i <= 200; i++) {
    var coordinates = [120.03 + Math.random(), 30.03 + Math.random()];
    var feature = new ol.Feature(new ol.geom.Point(coordinates));
    source.addFeature(feature);
  }
  for (var i = 1; i <= 200; i++) {
    var coordinates = [120.04 + Math.random(), 30.04 + Math.random()];
    var feature = new ol.Feature(new ol.geom.Point(coordinates));
    source.addFeature(feature);
  }

  // 聚合
  var cluster = new ol.source.Cluster({
    source: source,
    distance: 100
  })

  // 创建图层
  layer = new ol.layer.Vector({
    source: cluster,
    style: function (feature, resolution) {
      var size = feature.get('features').length;
      if (size == 1) {
        return new ol.style.Style({
          image: new ol.style.Icon({
            src: require('../assets/images/zuzhitubiao.png')
          })
        })
      }
      else {
        return new ol.style.Style({
          image: new ol.style.Circle({
            radius: 30,
            stroke: new ol.style.Stroke({
              color: 'white'
            }),
            fill: new ol.style.Fill({
              color: 'blue'
            })
          }),
          text: new ol.style.Text({
            text: size.toString(),
            fill: new ol.style.Fill({
              color: 'white'
            })
          })
        })
      }
    }
  });
  // 创建地图
  map = new ol.Map({
    target: 'map',
    controls: ol.control.defaults({ attribution: false, zoom: false }),
    view: new ol.View({
      center: [117.73714744925228, 36.861579290645864],
      zoom: 12,
      projection: 'EPSG:4326',
      multiWorld: true
    }),
    layers: [
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          key: '1d109683f4d84198e37a38c442d68311'
        })
      }),
      new ol.layer.Tile({
        source: new ol.source.Tianditu({
          layerType: 'vec',
          isLabel: true,
          key: '1d109683f4d84198e37a38c442d68311'
        })
      }),
      layer
    ]
  })
  map.on('moveend', function (event) {
    if (map.getView().getZoom() == map.getView().getMaxZoom()) {
      cluster.setDistance(0);
    }
    else {
      cluster.setDistance(100);
    }
  });
}
</script>

<style scoped lang='less'>
#map {
  width: 100vw;
  height: 100vh;
}
</style>