<template>
  <router-view />
</template>
<style lang="less">
* {
  padding: 0;
  margin: 0;
}
</style>

