import httpRequest from '../server/index'
//获取体育场所
export const getSportfieldListNotdel = (input) => {
  return httpRequest({
    url: '/sportmap/sportfield/listNotdel',
    method: 'get'
  })
}
//获取设施分布
export const getVenueListNotDel = (input) => {
  return httpRequest({
    url: '/sportmap/venue/listNotDel',
    method: 'get'
  })
}
//获取体育产业
export const getSportindustryListNotdel = (input) => {
  return httpRequest({
    url: '/sportmap/sportindustry/listNotDel',
    method: 'get'
  })
}
//获取体育组织
export const getSportorgListNotdel = (input) => {
  return httpRequest({
    url: '/sportmap/sportorg/listNotdel',
    method: 'get'
  })
}
//获取设施详情
export const getSportmapVenue = (id) => {
  return httpRequest({
    url: '/sportmap/venue/' + id,
    method: 'get'
  })
}
//获取体育场地详情
export const getSportmapSportfield = (id) => {
  return httpRequest({
    url: '/sportmap/sportfield/' + id,
    method: 'get'
  })
}
//获取体育产业详情
export const getSportmapSportindustry = (id) => {
  return httpRequest({
    url: '/sportmap/sportindustry/' + id,
    method: 'get'
  })
}
//获取体育组织详情
export const getSportmapSportorg = (id) => {
  return httpRequest({
    url: '/sportmap/sportorg/' + id,
    method: 'get'
  })
}

//获取体育组织
export const getLatestSocketData = (input) => {
  return httpRequest({
    url: '/api/RealLocationService/GetPageListByES',
    data: data,
    method: 'post'
  })
}